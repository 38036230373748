alert = (function() {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {
        shade = document.getElementById("shad_abox") || document.getElementById("shade");
        albox = document.getElementById("abox") || document.getElementById("alert_box");

        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {
        $(albox).removeClass('actif');
        $(shade).off("click", closeAlertBox).removeClass('actif');
        $("body").removeClass("alert_open");
    }

    function alert(str) {
        $("body").addClass("alert_open");

        if ($(".txt_alert").length) { // Desktop version

            $(".txt_alert", albox).html(str);
        } else { // Mobile version

            $(".alert_box_content", albox).html(str);
        }


        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);

        $(shade).addClass("actif");
        $(albox).addClass('actif');
    }

    window.addEventListener("load", init);

    return alert;
})();
