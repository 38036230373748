$(function () {
    if ($('.pageGiftCard').length) {
        var existing_card_btn = $('#showAmount').detach();
        var create_card_btn = $('.giftCard_btn').detach();
        $('section.pageGiftCard__main--infos .pageGiftCard__bloc').append(create_card_btn);

        // Add HTML class to show all giftcard section after blocs being moved
        $('.pageGiftCard').addClass('displayed');
    }
});


function toggleGiftCardBalance() {

}